<template>
  <section>
    <template v-if="currType === 'article'">
      <putin-article
        :params="putMeta"
        :user-info="userInfo"
        :videoable="videoable"
        :imageable="imageable"
        @change-mode="onChangeMode"
      />
    </template>
    <template v-else-if="currType === 'video'">
      <putin-video
        :params="putMeta"
        :user-info="userInfo"
        :imageable="imageable"
        :articlable="articlable"
        @change-mode="onChangeMode"
      />
    </template>
    <template v-else>
      <putin-image
        :params="putMeta"
        :user-info="userInfo"
        :videoable="videoable"
        :articlable="articlable"
        @change-mode="onChangeMode"
      />
    </template>
  </section>
</template>

<script>
  import lib from '@/api/co.lib'
  import objUser from '@/api/co.user'

  export default {
    metaInfo: { title: '上传参赛作品' },
    components: {
      PutinArticle: () => import('@/pages/sections/activity/PutinArticle.vue'),
      PutinImage: () => import('@/pages/sections/activity/PutinImage.vue'),
      PutinVideo: () => import('@/pages/sections/activity/PutinVideo.vue'),
    },
    data () {
      return {
        putMeta: {},
        userInfo: {},
        currType: 'image',
        submitTypes: [],
        imageable: false,
        videoable: false,
        articlable: false
      }
    },
    created () {
      this.putMeta = JSON.parse(lib.cookie.get('ActivityPutin'))
      this.currType = this.putMeta.current || ''
      const str = this.putMeta.submitType || 'image'
      this.submitTypes = str.split(',')
      if (!this.currType) {
        this.currType = this.submitTypes[0] || 'image'
      }

      this.userInfo = objUser.info.get()

      this.setUploadables()

      // console.log('this.submitTypes: %o', this.submitTypes)
    },
    methods: {
      onChangeMode (val) {
        this.currType = val
      },
      setUploadables () {
        if (this.submitTypes.indexOf('image') !== -1) {
          this.imageable = true
        } else {
          this.imageable = false
        }

        if (this.submitTypes.indexOf('video') !== -1) {
          this.videoable = true
        } else {
          this.videoable = false
        }

        if (this.submitTypes.indexOf('article') !== -1) {
          this.articlable = true
        } else {
          this.articlable = false
        }
      },
    }
  }
</script>
